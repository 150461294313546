import axios from "axios";
import { navigate } from "gatsby-link";
import React, { useEffect, useRef, useState } from "react";
import { AlertCircle, Loader, Send, X } from "react-feather";
import "./popupform.scss";

const PopupForm = ({ onClose, style, type, flair }) => {
  const firstField = useRef(null);
  const [loading, setLoad] = useState(false);
  const [fIndex, setfIndex] = useState(0);
  const [isAnimEnd, setAnimEnd] = useState(false);
  const flairs = [
    "FLOWER PUFF",
    "DEAL CHICA",
    "PEOPLES FAV",
    "INDIE SOUL",
    flair,
    "WAVE MAKER",
    "TULIP LAYDY",
  ];
  useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      if (fIndex !== flairs.length - 1) {
        setfIndex(fIndex + 1);
      } else {
        setAnimEnd(true);
      }
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [fIndex]);
  const [formData, setFormData] = useState({
    store_url: "",
    email: "",
    type: style + "-" + type,
  });
  const [errs, raiseErr] = useState([]);
  const setEmail = (email) => {
    setFormData({ ...formData, email: email });
  };
  //   const setSite=(site)=>{
  //     setFormData({...formData, store_url:site})
  //   }
  const validateForm = () => {
    // Check if fields are empty
    // for (let key in formData) {
    if (
      formData["email"] === "" ||
      formData["email"] === null ||
      typeof formData["email"] === "undefined"
    ) {
      raiseErr((errs) => [...errs, "Please fill out your email address :("]);
      return false;
    }
    // }
    let emailRegex = /\S+@\S+\.\S+/;
    let urlRegex = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator

    // check if email is invalid
    if (!emailRegex.test(formData.email)) {
      raiseErr((errs) => [...errs, "Please enter a valid email!"]);
      return false;
    }
    // check if site url is valid url
    // else if (!urlRegex.test(formData.store_url)){
    //     raiseErr(errs=>[...errs,'Please enter a valid url'])
    //     return false
    // }

    return true;
  };

  const submitForm = (e) => {
    e.preventDefault();

    // validate
    if (validateForm()) {
      //start loading
      setLoad(true);

      // hit the api
      axios
        .post(
          "https://aws-prod.kubric.io/api/v1/leadgen/shopify",
          JSON.stringify(formData),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          }
        )
        .then((res) => {
          console.log(res);
          // trigger gtag event
          typeof window !== "undefined" &&
            "gtag" in window &&
            window.gtag("event", "complete_registration", {});
          // trigger gtag adwords event
          typeof window !== "undefined" &&
            "gtag" in window &&
            window.gtag("event", "conversion", {
              send_to: "AW-627505787/CE8CCJqUv4ACEPv0m6sC",
            });
          // trigger fb pixel event
          typeof window !== "undefined" &&
            "fbq" in window &&
            window.fbq("track", "CompleteRegistration");
          setLoad(false);
          navigate("/success/");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(errs);
    }
  };
  return (
    <div className="overlay-bg">
      <div className="close-btn" onClick={onClose}>
        <X />
      </div>
      <div className="form-wrap">
        <div className="popup-form">
          <h3 className="reg flair">
            Looks like you are a
            <br />
            <br />
            {flairs.map((f, i) => (
              <b
                className={`ftag ${fIndex === i ? "on" : "off"} ${
                  isAnimEnd ? "blur" : ""
                }`}
              >
                {f}
              </b>
            ))}
          </h3>
          <div className={`form-content ${isAnimEnd ? "on" : "off"}`}>
            <h3 className="light">
              Your website personality is just 1 click away!
            </h3>
            <div className="errors">
              {errs.map((err) => (
                <div className="err">
                  <AlertCircle />
                  &ensp;
                  <span>{err}</span>
                </div>
              ))}
            </div>
            <form
              onSubmit={submitForm}
              className={`form-fields ${loading ? "formload" : ""}`}
            >
              <input
                autoComplete="email"
                type="text"
                ref={firstField}
                value={formData.email}
                onChange={(e) => setEmail(e.target.value)}
                // onKeyPress={handleKeypress}
                placeholder="Enter your email"
                className="field"
              />
              <button
                type="submit"
                className={`cta subform ${loading ? "loader" : ""}`}
              >
                {loading ? <Loader /> : <Send />}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupForm;
