import React from "react";
import "./header.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const Header = () => {
  return (
    <div className="head-wrap marquee">
      <Helmet>
        <title>ModeMagic | Badges</title>
      </Helmet>
      <div className="content marquee-inner">
        {Array(2)
          .fill(null)
          .map((_item) => (
            <div className="slide">
              <div className="bit">
                ✨<b>MODE</b>MAGIC✨
                {/* <img className='logo' src="https://media.kubric.io/api/assetlib/43e3c4d8-ed94-4a97-9ec8-0ab95746d4b4.svg" alt="logo"/> */}
                {/* <StaticImage
                  className="logo"
                  objectFit="contain"
                  src="../../images/mmlogo.svg"
                  alt="logo"
                  placeholder='tracedSVG'
                /> */}
              </div>
              +
              <div className="bit">
                <StaticImage
                  className="slogo"
                  objectFit="contain"
                  src="../../images/slogo.svg"
                  alt="shopify logo"
                  placeholder='tracedSVG'
                />
                &ensp;
                <b>YOUR</b>&nbsp;<span className="light">STORE</span>
              </div>
              =
              <div className="bit">
                <b>15% 🚀 MORE SALES</b>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Header;
