import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import "../styles/styles.scss";
import { graphql } from "gatsby";

import "../styles/styles.scss";
import PopupForm from "../components/PopupForm/PopupForm";
import Question from "../components/Question/Question";

import { Link, scroller } from "react-scroll";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = ({ data }) => {
  const cmsref = data.sanityLp;
  // const generateImage=(asset)=>(
  //   getGatsbyImageData(asset, sanityConfig)
  // )
  const [isPop, setPop] = useState(false);
  const [ans, setAns] = useState([]);
  const [xtag, setTag] = useState(null);
  const [isStart, setStart] = useState(false);
  const popup = () => {
    setPop(!isPop);
    // trigger gtag analytics event
    typeof window !== "undefined" &&
      "gtag" in window &&
      window.gtag("event", "initiate_checkout", {});
    // trigger fb pixel event
    typeof window !== "undefined" &&
      "fbq" in window &&
      window.fbq("track", "InitiateCheckout");
  };
  const gonext = (label, tag, index) => {
    if (index === 0) {
      setTag(tag);
      setAns((ans) => [...ans, label]);
      scroller.scrollTo("ques2", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    } else if (index === cmsref.ques.length - 1) {
      if (xtag !== null) {
        setAns((ans) => [...ans, label]);
        popup();
      } else {
        scroller.scrollTo("ques1", {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      }
    } else {
      if (ans.length < index) {
        console.log(("ques" + index).toString());
        scroller.scrollTo(("ques" + index).toString(), {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      } else {
        setAns((ans) => [...ans, label]);
        scroller.scrollTo(("ques" + (index + 2)).toString(), {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0,1);
    window.addEventListener("scroll", () => {
      setStart(true);
    });
  }, []);

  return (
    <div className="site-wrap">
      <Header />
      <div className="page-wrap">
        <section className="hero">
          <h3>{cmsref.h_title}</h3>
          <h1 className="med">
            <div className="deco a">
              <StaticImage
                placeholder="blurred"
                src="../images/flower.svg"
                alt="flower"
              />
            </div>
            {cmsref.tag_title}
            <div className="deco b">
              <StaticImage
                placeholder="blurred"
                src="../images/flower.svg"
                alt="flower"
              />
            </div>
          </h1>
          <Link
            activeClass="active"
            to="ques1"
            spy={true}
            smooth={true}
            duration={500}
          >
            <div className="rcta cta white" onClick={() => setStart(!isStart)}>
              <span>Find your personality</span>
              <StaticImage
                className="chevs"
                src="../images/cdown.svg"
                alt="chevrons"
                placeholder="blurred"
              />
            </div>
          </Link>
        </section>

        <div className="quizwrap">
          {cmsref.ques.map((q, i) => (
            <Question
              data={q}
              index={i}
              onNext={gonext}
              start={isStart}
              maxQues={cmsref.ques.length}
            />
          ))}
        </div>
        {isPop ? (
          <PopupForm
            onClose={() => {
              setPop(!isPop);
            }}
            flair={xtag}
            style={ans[0]}
            type={ans[1]}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  {
    sanityLp {
      tag_title
      h_title
      ques {
        q_title
        s_opts {
          _key
          _type
          style_name
          character
          slug
          style_img {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;
