import React from "react";
import "./question.scss";

const Question = ({ data, index, maxQues, onNext, start }) => {
  return (
    <div
      id="ques-wrap"
      className={`ques-wrap ques${index + 1} ${start ? "started" : ""}`}
    >
      <p className="caption">Question {index + 1}/{maxQues}</p>
      <h2 className="reg">{data.q_title}</h2>
      <div className="options-wrap">
        {data.s_opts.map((opt) => (
          <div
            className="opt"
            onClick={() => {
              onNext(opt.slug, opt.character, index);
            }}
          >
            <img src={opt.style_img.asset.url} alt="" />
            <h4 className="reg">{opt.style_name}</h4>
          </div>
        ))}
        <br />
      </div>
    </div>
  );
};

export default Question;
